import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[preventClickPropagation]',
  standalone: false
})
export class PreventClickPropagationDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    $event.stopPropagation();
  }
}
